import React, { useState } from 'react';
import { Icon, PrimaryButton, Typography } from 'glints-aries/es/@next';
import { Popover } from 'glints-aries/es/@next/Popover';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/es/@next/utilities/spacing';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { WhatsAppGreen } from '../../../../../common/color';
import {
  trackWAPopoverInCommunicationClickedEvent,
  WhatsAppContactPopoverSources,
} from '../../../../../components/ApplicantWhatsAppContactPopover/tracking';
import { useBuildWhatsAppMessageAndRedirect } from '../../../../../components/ApplicantWhatsAppContactPopover/useBuildWhatsAppMessageAndRedirect';
import { useGetApplicationWithChannelAndMetadata } from '../../../graphql';
import { useEmpRespondSideEffect } from '../../../hooks';
import ChannelHeaderMenuButton from '../ChannelHeaderMenuButton';
import { messages } from './messages';

const Content = styled.div`
  background-color: ${Neutral.B18};
  width: 318px;

  .description {
    margin-top: ${space8};
  }
  .cta-button {
    margin-top: ${space16};
  }

  &[data-has-cta='true'] {
    width: unset;
  }
`;

const Button = styled(ChannelHeaderMenuButton)`
  svg {
    fill: ${WhatsAppGreen};
  }
`;

export const WhatsAppChannelHeaderMenuButton = () => {
  const { data, loading } = useGetApplicationWithChannelAndMetadata();
  const application = data?.getApplicationById;

  const { whatsAppNumber, isAvailable: whatsappAvailable } =
    application?.whatsAppDetails || {
      whatsAppNumber: null,
      isAvailable: false,
    };

  const { formatMessage } = useIntl();

  const [popoverActive, setPopoverActive] = useState(false);
  const empRespondSideEffect = useEmpRespondSideEffect();
  const { buildWhatsAppMessageAndRedirect } =
    useBuildWhatsAppMessageAndRedirect();

  const openChatWindow = () => {
    if (!application) {
      console.error(`No application found!`);
      return;
    }
    buildWhatsAppMessageAndRedirect({
      applicant: { ugcFullName: application.applicant.ugcFullName || '' },
      whatsAppDetails: application.whatsAppDetails,
      applicationDetails: application,
      job: application.job,
      source: WhatsAppContactPopoverSources.CHAT,
    });
  };

  const moveToInCommunication = async () => {
    await empRespondSideEffect();
    if (application) {
      trackWAPopoverInCommunicationClickedEvent({
        applicationId: application.id,
        jobId: application.job.id,
        source: WhatsAppContactPopoverSources.CHAT,
      });
    }
    setPopoverActive(false);
  };

  const getPopoverTitleAndDescription = () => {
    if (whatsappAvailable && !whatsAppNumber) {
      return {
        title: formatMessage(messages.whatsApp),
        description: formatMessage(messages.toContactMoveOrChat),
      };
    }

    return {
      title: formatMessage(messages.noWhatsAppNumber),
      description: formatMessage(messages.whatsAppNotIncluded),
    };
  };

  const handleMouseEnter = () => {
    if (whatsAppNumber) {
      return;
    }
    setPopoverActive(true);
  };

  const disableButton = !whatsAppNumber;

  const activator = (
    <div onMouseEnter={handleMouseEnter}>
      <Button
        icon={<Icon name="ri-whatsapp-fill" className="whatsapp-icon" />}
        iconPosition="left"
        disabled={disableButton}
        onClick={openChatWindow}
        loading={loading}
        data-cy="exchange-whatsapp-number-btn"
      >
        {formatMessage(messages.whatsAppCandidate)}
      </Button>
    </div>
  );

  const { title, description } = getPopoverTitleAndDescription();
  const showCta = whatsappAvailable;

  return (
    <div onMouseLeave={() => setPopoverActive(false)}>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={() => setPopoverActive(false)}
        preferredAlignment="left"
        zIndexOverride={1000}
      >
        <Popover.Pane fixed={true}>
          <Content data-has-cta={showCta}>
            <Popover.Section>
              <Typography variant={'body2'} color={Neutral.B100}>
                {title}
              </Typography>
              <Typography
                className="description"
                variant={'subtitle2'}
                color={Neutral.B100}
              >
                {description}
              </Typography>
              <If condition={showCta}>
                <PrimaryButton
                  fullWidth={true}
                  className="cta-button"
                  onClick={moveToInCommunication}
                  data-cy="move-to=communication-btn"
                >
                  {formatMessage(messages.moveToInCommunication)}
                </PrimaryButton>
              </If>
            </Popover.Section>
          </Content>
        </Popover.Pane>
      </Popover>
    </div>
  );
};
