import {
  Job,
  JobScreeningQuestionSelectedQuestions,
} from '../../../common/interfaces/entities';
import { JobFormState, JobPostingPayload } from '../common/constants';

const isScreeningQuestionSelected = (
  screeningQuestions: JobFormState['screeningQuestions'],
  type: JobScreeningQuestionSelectedQuestions
) => screeningQuestions?.selectedQuestions?.includes(type);

export const convertScreeningQuestionFormStateToPayload = ({
  screeningQuestions,
  isEdit,
  isVIPActive,
}: {
  screeningQuestions: JobFormState['screeningQuestions'];
  isEdit: boolean;
  isVIPActive: boolean;
}): Pick<JobPostingPayload, 'screeningQuestions'> | null => {
  const includeScreeningQuestion =
    !isEdit &&
    isVIPActive &&
    screeningQuestions?.selectedQuestions?.length &&
    screeningQuestions.selectedQuestions.length;

  if (!includeScreeningQuestion) {
    return null;
  }

  return {
    screeningQuestions: {
      selectedQuestions: screeningQuestions?.selectedQuestions || [],
      ...(isScreeningQuestionSelected(
        screeningQuestions,
        JobScreeningQuestionSelectedQuestions.INTRO_VIDEO
      ) && {
        introVideoPreferredLanguage:
          screeningQuestions?.introVideoPreferredLanguage,
      }),
      ...(isScreeningQuestionSelected(
        screeningQuestions,
        JobScreeningQuestionSelectedQuestions.INDUSTRY_EXPERIENCE
      ) && {
        industryIds: screeningQuestions?.industries?.map(
          industry => industry.id
        ),
      }),
      ...(isScreeningQuestionSelected(
        screeningQuestions,
        JobScreeningQuestionSelectedQuestions.DOCUMENT_CERTIFICATE_AVAILABILITY
      ) && {
        documentAndCertificateIds:
          screeningQuestions?.documentAndCertificates?.map(
            document => document.id
          ),
      }),
    },
  };
};

export const convertScreeningQuestionResponseToFormState = ({
  screeningQuestions,
  isVIPActive,
  isEdit,
}: {
  screeningQuestions: Job['screeningQuestions'];
  isVIPActive: boolean;
  isEdit: boolean;
}): JobFormState['screeningQuestions'] => {
  const noScreeningQuestion =
    !screeningQuestions || screeningQuestions?.selectedQuestions.length < 1;

  const shouldResetScreeningQuestionField = !isEdit && !isVIPActive;

  if (shouldResetScreeningQuestionField || noScreeningQuestion) {
    return undefined;
  }

  return {
    selectedQuestions: screeningQuestions.selectedQuestions,
    introVideoPreferredLanguage:
      screeningQuestions.introVideoPreferredLanguageDetail?.code || undefined,
    industries:
      screeningQuestions.industries.length > 0
        ? screeningQuestions.industries
        : undefined,
    documentAndCertificates:
      screeningQuestions.documentAndCertificates.length > 0
        ? screeningQuestions.documentAndCertificates
        : undefined,
  };
};
