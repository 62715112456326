export const UnleashFeatureNames = {
  empFollowUpRequest: 'empFollowUpRequest',
  empFollowUpRequestNewFeatureAlert: 'empFollowUpRequestNewFeatureAlert',
  empLimitPendingCandidates: 'empLimitPendingCandidates',
  empIsNewLoginVerificationEnabled: 'empIsNewLoginVerificationEnabled',
  empChatLimitGlintsUsers: 'empChatLimitGlintsUsers',
  empInterviewProcess: 'empInterviewProcess',
  empNewResumeViewer: 'empNewResumeViewer',
  empPaginatedCompanyJobs: 'empPaginatedCompanyJobs',
  empApplicationStatusTracking: 'empApplicationStatusTracking',
  empUpdatedTermsAndConditionsEnabled: 'empUpdatedTermsAndConditionsEnabled',
  empProcessAfterTopUpActionOnBackend: 'empProcessAfterTopUpActionOnBackend',
  empEmployerInitChat: 'empEmployerInitChat',
  empShowJobAccessBanner: 'empShowJobAccessBanner',
  empIsVerificationPromptEnabled: 'empIsVerificationPromptEnabled',
  empZaloNotification: 'empZaloNotification',
  empShowWhatsappIcon: 'empShowWhatsappIcon',
  empCSVDownloadEnabled: 'empCSVDownloadEnabled',
  empDashboardStatistics: 'empDashboardStatistics',
  empBoostJob: 'empBoostJob',
  empShowBoostJob30MinutesDelayBanner: 'empShowBoostJob30MinutesDelayBanner',
  empWhatsappIntegration: 'empWhatsappIntegration',
  empRecommendedTalents: 'empRecommendedTalents',
  empPDFInvoice: 'empPDFInvoice',
  empAgeGenderForJob: 'empAgeGenderForJob',
  empEmbeddedRecommendedTalents: 'empEmbeddedRecommendedTalents',
  empAllowCollegeDegreeInVN: 'empAllowCollegeDegreeInVN',
  empMustHaveJobLocationAndEducation: 'empMustHaveJobLocationAndEducation',
  webEmpDisableJobCopy: 'webEmpDisableJobCopy',
  empCreditSystem: 'empCreditSystem',
  empTalentSearchAPIV2: 'empTalentSearchAPIV2',
  empRecommendedTalentAPIV2: 'empRecommendedTalentAPIV2',
  empJobTrafficWarning: 'empJobTrafficWarning',
  empAppDownloadCTA: 'empAppDownloadCTA',
  empEnableResponseRate: 'empEnableResponseRate',
  empVIPMembership: 'empVIPMembership',
  empEnableMonetisationInVN: 'empEnableMonetisationInVN',
  allScreeningQuestion: 'allScreeningQuestion',
  empNotificationChannelPreferencesSurvey:
    'empNotificationChannelPreferencesSurvey',
  empNotifyNewVNMonetizedFeatures: 'empNotifyNewVNMonetizedFeatures',
  empPremiumFilters: 'empPremiumFilters',
  empHideMajorPremiumFilter: 'empHideMajorPremiumFilter',
  empSelfServeCVFinder: 'empSelfServeCVFinder',
  empUseMobileAppPrompt: 'empUseMobileAppPrompt',
  allUseOSS: 'allUseOSS',
  empJobTitleAndJobRoleRecommendations: 'empJobTitleAndJobRoleRecommendations',
};
