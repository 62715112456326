import { VIPMembershipBenefit } from './types';

type Props = {
  isScreeningQuestionEnabled: boolean;
  isPremiumFilterEnabled: boolean;
};

export const vipBenefitsPopoverPromotionImages = {
  requiredApplicationFields: {
    id: '/images/vip-benefits/required-application-fields-id.png',
    en: '/images/vip-benefits/required-application-fields-en.png',
  },
  mandatoryResumeUpload: {
    id: '/images/vip-benefits/mandatory-resume-upload-id.png',
    en: '/images/vip-benefits/mandatory-resume-upload-en.png',
  },
  hideSalary: {
    id: '/images/vip-benefits/hide-salary-id.png',
    en: '/images/vip-benefits/hide-salary-en.png',
  },
  screeningQuestions: {
    id: '/images/vip-benefits/screening-questions-id.png',
    en: '/images/vip-benefits/screening-questions-en.png',
  },
  premiumFilters: {
    id: '/images/vip-benefits/premium-filters-id.png',
    en: '/images/vip-benefits/premium-filters-en.png',
  },
  highlightedJobCard: {
    id: '/images/vip-benefits/highlighted-job-card-id.png',
    en: '/images/vip-benefits/highlighted-job-card-en.png',
  },
};

const getScreeningQuestionDescription = ({
  isScreeningQuestionEnabled,
}: Pick<Props, 'isScreeningQuestionEnabled'>) => {
  if (isScreeningQuestionEnabled) {
    return {
      description: {
        id: 'text-save-time-and-hire',
        defaultMessage:
          'Save time and hire smarter with pre-screening questions that pinpoint top candidates instantly!',
      },
      promotionImages: vipBenefitsPopoverPromotionImages.screeningQuestions,
    } as const;
  }

  return {
    description: {
      id: 'text-coming-soon',
      defaultMessage: 'Coming Soon',
    },
    isComingSoon: true,
  } as const;
};

const getPremiumFilterDescription = ({
  isPremiumFilterEnabled,
}: Pick<Props, 'isPremiumFilterEnabled'>) => {
  if (isPremiumFilterEnabled) {
    return {
      description: {
        id: 'text-elevate-your-hiring-with.2',
        defaultMessage:
          'Elevate your hiring with premium filters to swiftly identify top candidates',
      },
      promotionImages: vipBenefitsPopoverPromotionImages.premiumFilters,
    } as const;
  }

  return {
    description: {
      id: 'text-coming-soon',
      defaultMessage: 'Coming Soon',
    },
    isComingSoon: true,
  } as const;
};

export const getVIPMembershipBenefits = ({
  isScreeningQuestionEnabled,
  isPremiumFilterEnabled,
}: Props): VIPMembershipBenefit[] => [
  {
    imageSrc:
      '/images/credit-system/vip-benefits/required-application-fields.svg',
    heading: {
      id: 'text-required-application-fields',
      defaultMessage: 'Required Application Fields',
    },
    description: {
      id: 'text-mark-certain-fields-to',
      defaultMessage:
        'Mark certain fields to be required to submit when candidates apply for your jobs.',
    },
    promotionImages:
      vipBenefitsPopoverPromotionImages.requiredApplicationFields,
  },
  {
    imageSrc: '/images/credit-system/vip-benefits/mandatory-resume-upload.svg',
    heading: {
      id: 'text-mandatory-resume-upload',
      defaultMessage: 'Mandatory Resume Upload',
    },
    description: {
      id: 'text-candidates-will-be-required',
      defaultMessage:
        'Candidates will be required to upload their resumes when they apply for your jobs.',
    },
    promotionImages: vipBenefitsPopoverPromotionImages.mandatoryResumeUpload,
  },
  {
    imageSrc: '/images/credit-system/vip-benefits/highlighted-job-card.svg',
    heading: {
      id: 'text-highlighted-job-card',
      defaultMessage: 'Highlighted job card',
    },
    description: {
      id: 'text-stand-out-among-the',
      defaultMessage:
        'Stand out among the rest with a VIP exclusive job card design and badge next to your company name.',
    },
    promotionImages: vipBenefitsPopoverPromotionImages.highlightedJobCard,
  },
  {
    imageSrc: '/images/credit-system/vip-benefits/hide-salary.svg',
    heading: {
      id: 'text-hide-salary',
      defaultMessage: 'Hide Salary',
    },
    description: {
      id: 'text-do-not-show-your',
      defaultMessage:
        'Do not show your salary to job seekers that view your job post.',
    },
    promotionImages: vipBenefitsPopoverPromotionImages.hideSalary,
  },
  {
    imageSrc: '/images/credit-system/vip-benefits/screening-questions.svg',
    heading: {
      id: 'text-screening-questions',
      defaultMessage: 'Screening Questions',
    },
    ...getScreeningQuestionDescription({ isScreeningQuestionEnabled }),
  },
  {
    imageSrc: '/images/credit-system/vip-benefits/premium-filters.svg',
    heading: {
      id: 'text-premium-filters',
      defaultMessage: 'Premium Filters',
    },
    ...getPremiumFilterDescription({ isPremiumFilterEnabled }),
  },
];
