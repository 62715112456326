import { useMemo } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import { reduce } from 'lodash-es';

const query = gql`
  query getVIPJobFieldsDefaultValue {
    getVIPJobFieldsDefaultValue {
      fieldName
      defaultValue
    }
  }
`;

type QueryOutput = {
  getVIPJobFieldsDefaultValue: {
    fieldName: string;
    defaultValue: boolean;
  }[];
};

const convertDefaultValueArrayToObject = (
  defaultValueArray: QueryOutput['getVIPJobFieldsDefaultValue']
) =>
  reduce<
    QueryOutput['getVIPJobFieldsDefaultValue'][number],
    Record<string, boolean>
  >(
    defaultValueArray,
    (acc, { fieldName, defaultValue }) => {
      acc[fieldName] = defaultValue;
      return acc;
    },
    {}
  );

export default function useGetVIPJobFieldsDefaultValue() {
  const { data } = useSuspenseQuery<QueryOutput>(query, {
    fetchPolicy: 'cache-first',
  });

  const vipJobFieldsDefaultValue = useMemo(
    () => convertDefaultValueArrayToObject(data.getVIPJobFieldsDefaultValue),
    [data.getVIPJobFieldsDefaultValue]
  );

  return {
    vipJobFieldsDefaultValue,
  } as const;
}
