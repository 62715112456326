import { kebabCase } from 'lodash-es';

export const SwrCacheKeys = {
  applicationDetails: (jobId: string, applicationId: string) =>
    `get-application-details-${jobId}-${applicationId}`,
  jobApplications: (jobId: string) => `get-job-applications-${jobId}`,
  getTalents: 'get-talents',
  talentDetails: (talentId: string) => `get-talent-details-${talentId}`,
  companyJobStatusBreakdown: 'get-company-job-status-breakdown',
  getRecommendedHierarchicalJobCategory: (suffix?: string) =>
    suffix
      ? `get-recommended-hierarchical-job-category-${kebabCase(suffix)}`
      : 'get-recommended-hierarchical-job-category',
};
